<template>
    <div class="about">
        如需浏览，请长按网站复制后使用浏览器访问111
        <div style="font-size: 24px; margin-top: 10px">http://www.panhaodian.cn/</div>
    </div>
</template>
<script>
    import { getIsWxClient } from '@/assets/connJs/menu-func'
    export default {
        mounted() {
            if (!getIsWxClient()) {
                // window.location.href = "http://www.panhaodian.cn?aa=123123"
            }
        }
    }
</script>

<style lang="less" scoped>
.about {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  font-size: 24px;
  padding: 0 20px;
}
</style>
